<template>
    <div>
        <b-container class="mb-4">
            <b-row cols="12" class="p-2 py-4 justify-content-center bg-sukudai-green">
                <b-col cols="2" class="w-100"
                       style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                <b-col cols="6">
                    <h2 class="text-white col-12 text-center font-weight-bolder">常見問題</h2>
                </b-col>
                <b-col cols="2" class="w-100"
                       style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
            </b-row>

            <div>
                <b-container class="bv-example-row py-2">
                    <b-row class="my-3 justify-content-center">
                        <b-col class="mb-2 loan-type-col">
                            <b-button variant="warning" size="lg" @click="changeLoanType(0)"
                                      class="text-white font-weight-bold border-0 rounded-0 w-100 bg-sukudai-green">汽機車借貸
                            </b-button>
                        </b-col>

                        <b-col class="mb-2 loan-type-col">
                            <b-button variant="warning" size="lg" @click="changeLoanType(1)"
                                      class="text-white font-weight-bold border-0 rounded-0 w-100 bg-sukudai-yellow">房屋貸款
                            </b-button>
                        </b-col>

                        <b-col class="mb-2 loan-type-col">
                            <b-button variant="warning" size="lg" @click="changeLoanType(2)"
                                      class="text-white font-weight-bold border-0 rounded-0 w-100 bg-sukudai-green">信用貸款
                            </b-button>
                        </b-col>

                        <b-col class="mb-2 loan-type-col">
                            <b-button variant="warning" size="lg" @click="changeLoanType(3)"
                                      class="text-white font-weight-bold border-0 rounded-0 w-100 bg-sukudai-yellow">商品貸款
                            </b-button>
                        </b-col>
                        <b-col class="mb-2 loan-type-col">
                            <b-button variant="warning" size="lg" @click="changeLoanType(4)"
                                      class="text-white font-weight-bold border-0 rounded-0 w-100 bg-sukudai-green">企業貸款
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <div v-if="!showMessage">
                <b-row class="justify-content-center" v-for="items in items" :key="items.id">
                    <b-col cols="10" lg="9" class="my-2 p-3 pl-4 bg-sukudai-light-grey">
                        <div class="pl-4 border-left border-dark">
                            <p class="bg-sukudai-title-info py-1 pl-2 pr-5 font-weight-bolder font-lg" style="width: fit-content">
                                {{ items.question }}
                            </p>
                            <span>{{ items.answer }}</span>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-else>
                <div class="mt-2 mb-4 p-4 bg-sukudai-light-grey text-center">
                    <span>{{ msg }}</span>
                </div>
            </div>

            <b-row  class="justify-content-center">
                <b-button variant="warning" size="lg"
                          class="text-white font-xl font-weight-bolder py-1 mx-3 d-none d-lg-block"
                          :to="{ path: 'FormConsultation' }">
                    表單諮詢
                </b-button>
                <b-button variant="warning" size="lg"
                          class="text-white font-xl font-weight-bolder py-1 mx-3"
                          @click="$bvModal.show(modalId)">
                    立即諮詢
                </b-button>
            </b-row>
        </b-container>

        <b-modal :id="modalId" hide-footer>
            <quick-submit/>
        </b-modal>
    </div>
</template>

<script>
import quickSubmit from "@/components/quickSubmit";

export default {
    components: {
        quickSubmit
    },
    data() {
      return {
          items: [],
          msg: '',
          showMessage: false,
          modalId: 'contact-now-modal',
      }
    },

    created: function() {
        this.changeLoanType(0);
    },

    methods: {
        changeLoanType(loanType) {
            this.items = [];
            this.msg = '資料載入中...';
            this.showMessage = true;
            this.getCommonProblemRecords(loanType)
                .then(result => {
                    this.items = result.data.map((line, index) => {
                        return {
                            id: index,
                            question: line[1],
                            answer: line[2]
                        }
                    });

                    if (!this.items.length) {
                        this.msg = '尚無常見問題';
                    } else {
                        this.showMessage = false;
                    }
                })
                .catch(error => {
                    this.items = [];
                    this.msg = '資料載入失敗'
                    console.log(error);
                });
        },

        getCommonProblemRecords(loanType = 0) {
            if (loanType > 4 || loanType < 0) {
                loanType = 0;
            }

            return this.axios.get(
                `https://script.google.com/macros/s/AKfycby0BVEMrWpWk6mm__9lSWsuAWSOQZzLe2q6bOTxjiwoGxDbFB6kIUqA3c0KPS4pQilp2A/exec?type=${loanType}`,
                {
                    params: {type: loanType},
                    headers: {'Content-Type': 'application/json'}
                }
            )
        },
    }
}
</script>

<style lang="scss" scoped>
.loan-type-col {
    flex-grow: 0;
    flex-basis: 100%;

    @media (min-width: 576px) {
        flex-basis: 50%;
    }

    @media (min-width: 768px) {
        flex-basis: calc(100% / 3);
    }

    @media (min-width: 992px) {
        flex-basis: 20%;
    }
}
</style>